import { createTheme, createThemedStyled } from 'baseui';

const primitives = {};

const baseTheme = createTheme(primitives);

const theme = {
  ...baseTheme,
};

type CustomTheme = typeof theme;

export const styled = createThemedStyled<CustomTheme>();

export default theme;
