import { useAppState } from '../../context/appContext';
import { WodType } from '../../model/wod';

import styles from './index.module.scss';
import Score from './score';

interface CounterProps {
  onOpenDrawer: () => void;
}

const wodShortName = (wodType: WodType) => {
  switch (wodType) {
    default:
      return wodType.slice(0, 2);
  }
};

const Counter = ({ onOpenDrawer }: CounterProps) => {
  const { wod, reps } = useAppState();

  return (
    <div className={styles['main-container']}>
      <div className={styles['mode']} onClick={onOpenDrawer}>
        {wodShortName(wod.type)}
      </div>
      <div className={styles['score']}>
        <Score wod={wod} reps={reps} />
      </div>
    </div>
  );
};

export default Counter;
