import { Howl } from 'howler';

export enum Sound {
  ButtonClick = 'ButtonClick',
  DrawerOpen = 'DrawerOpen',
}

const sounds = {
  [Sound.ButtonClick]: new Howl({
    src: ['sounds/click.mp3'],
  }),
  [Sound.DrawerOpen]: new Howl({
    src: ['sounds/open.mp3'],
  }),
};

export const play = (sound: Sound) => {
  sounds[sound].play();
};
