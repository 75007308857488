import { AmrapWod } from './wod';

const repsInRound = (amrap: AmrapWod): number => {
  return amrap.exercises.reduce((acc, exercise) => acc + exercise.reps, 0);
};

export const currentBlock = (amrap: AmrapWod, reps: number) => {
  if (amrap.exercises.length === 0) {
    return undefined;
  }
  const repsPerRond = repsInRound(amrap);
  let repsInCurrentRound = reps % repsPerRond;
  let index = 0;
  while (repsInCurrentRound >= amrap.exercises[index].reps) {
    repsInCurrentRound -= amrap.exercises[index].reps;
    index++;
  }
  const currentExercise = amrap.exercises[index];
  const completedRounds = Math.floor(reps / repsPerRond);
  const repsDoneInCurrentRound = reps - completedRounds * repsPerRond;
  return {
    completedRounds,
    exercise: currentExercise.exercise,
    repsDoneInCurrentRound,
    remainingInBlock: currentExercise.reps - repsInCurrentRound,
    remainingInRound: repsPerRond - repsDoneInCurrentRound,
  };
};
