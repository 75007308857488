import { BaseProvider } from 'baseui';
import NoSleep from 'nosleep.js';
import React from 'react';
import ReactDOM from 'react-dom';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider } from 'styletron-react';

import App from './components/App';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

const noSleep = new NoSleep();

document.addEventListener(
  'click',
  function enableNoSleep() {
    noSleep.enable();
  },
  { once: true },
);

const engine = new Styletron();

ReactDOM.render(
  <React.StrictMode>
    <Provider value={engine}>
      <BaseProvider theme={theme}>
        <App />
      </BaseProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
