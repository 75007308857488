import { ANCHOR, Drawer } from 'baseui/drawer';
import { FormControl } from 'baseui/form-control';
import { OnChangeParams, Select } from 'baseui/select';
import { useCallback } from 'react';

import { useAppState } from '../../context/appContext';
import { WodType } from '../../model/wod';

import styles from './index.module.scss';
import WodMenu from './wodMenu';

interface AppMenuProps {
  isOpen?: boolean;
  onClose?: () => void;
}

const Index = ({ isOpen, onClose }: AppMenuProps) => {
  const { wod, setWodType, setWod } = useAppState();

  const handleChange = useCallback(
    (params: OnChangeParams) => {
      if (params.option) {
        setWodType(params.option.id as WodType);
      }
    },
    [setWodType],
  );

  return (
    <Drawer isOpen={isOpen} autoFocus onClose={onClose} anchor={ANCHOR.left}>
      <div className={styles['drawer-container']}>
        <FormControl label="Tipo de WOD">
          <Select
            placeholder={'Tipo de WOD'}
            clearable={false}
            options={Object.values(WodType).map((w) => ({
              id: w,
            }))}
            labelKey="id"
            valueKey="id"
            value={[{ id: wod.type }]}
            onChange={handleChange}
          />
        </FormControl>
        <WodMenu wod={wod} setWod={setWod} />
      </div>
    </Drawer>
  );
};

export default Index;
