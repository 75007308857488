import Button, { ButtonProps } from './Button';
import styles from './ButtonsLine.module.scss';

interface ButtonsLineProps {
  buttons: ButtonProps[];
}

const ButtonsLine = ({ buttons }: ButtonsLineProps) => {
  return (
    <div className={styles.buttonsLine}>
      {buttons.map((button, index) => (
        <Button key={index} {...button} />
      ))}
    </div>
  );
};

export default ButtonsLine;
