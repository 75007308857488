import { Button } from 'baseui/button';
import { FormControl } from 'baseui/form-control';
import { DeleteAlt } from 'baseui/icon';
import { Input } from 'baseui/input';
import { ListItem } from 'baseui/list';
import { OnChangeParams, Select } from 'baseui/select';
import { FormEvent, useCallback, useState } from 'react';

import { Exercises } from '../../../model/exercise';
import { AmrapWod } from '../../../model/wod';

interface AmrapMenuProps {
  amrap: AmrapWod;
  setAmrap: (amrap: AmrapWod) => void;
}

const defaultReps = 10;

const AmrapMenu = ({ amrap, setAmrap }: AmrapMenuProps) => {
  const [reps, setReps] = useState<number>(defaultReps);
  const [exercise, setExercise] = useState<string | undefined>(undefined);

  const handleRepsChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    setReps(event.currentTarget.valueAsNumber);
  }, []);

  const handleExerciseChange = useCallback((params: OnChangeParams) => {
    setExercise(params.option ? (params.option.id as string) : undefined);
  }, []);

  const handleAddExercise = useCallback(() => {
    if (exercise) {
      setAmrap({
        ...amrap,
        exercises: [
          ...amrap.exercises,
          {
            exercise: exercise,
            reps: reps,
          },
        ],
      });
      setExercise(undefined);
      setReps(defaultReps);
    }
  }, [amrap, exercise, reps, setAmrap, setExercise, setAmrap]);

  const handleDeleteExercise = useCallback(
    (index: number) => () => {
      const newExercises = [...amrap.exercises];
      newExercises.splice(index, 1);
      setAmrap({
        ...amrap,
        exercises: newExercises,
      });
    },
    [amrap, setAmrap],
  );

  return (
    <>
      <FormControl label="Ejercicio">
        <Select
          placeholder={'Ejercicio'}
          clearable={true}
          creatable={true}
          options={Object.values(Exercises).map((e) => ({
            id: e,
          }))}
          valueKey="id"
          labelKey="id"
          value={exercise ? [{ id: exercise }] : undefined}
          onChange={handleExerciseChange}
        />
      </FormControl>
      <FormControl label="Repeticiones">
        <Input
          placeholder="Repeticiones"
          value={reps}
          onChange={handleRepsChange}
          type="number"
          min={1}
        />
      </FormControl>
      <Button onClick={handleAddExercise} disabled={exercise === undefined}>
        Añadir
      </Button>
      <ul className="overflow-auto">
        {amrap.exercises.map((e, index) => (
          <ListItem
            key={index}
            artwork={() => e.reps}
            endEnhancer={() => (
              <span onClick={handleDeleteExercise(index)}>
                <DeleteAlt />
              </span>
            )}
          >
            {e.exercise}
          </ListItem>
        ))}
      </ul>
    </>
  );
};

export default AmrapMenu;
