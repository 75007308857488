import { RefObject, useCallback, useLayoutEffect, useRef, useState } from 'react';

interface Size {
  width: number;
  height: number;
}

const useSize = <T extends HTMLElement = HTMLDivElement>(): [RefObject<T>, Size] => {
  const ref = useRef<T>(null);
  const [size, setSize] = useState<Size>({ width: 0, height: 0 });

  const sizeChangeHandler = useCallback(() => {
    if (ref.current) {
      setSize({
        width: ref.current.offsetWidth,
        height: ref.current.offsetHeight,
      });
    }
  }, [setSize]);

  useLayoutEffect(() => {
    sizeChangeHandler();
    window.addEventListener('resize', sizeChangeHandler);

    return () => {
      window.removeEventListener('resize', sizeChangeHandler);
    };
  }, [sizeChangeHandler]);

  return [ref, size];
};

export default useSize;
