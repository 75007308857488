import useSize from '../../../hooks/useSize';
import { groupBy } from '../../../utils/array';

import { ButtonProps } from './Button';
import ButtonsLine from './ButtonsLine';
import style from './index.module.scss';

interface ButtonsBoardProps {
  buttons: ButtonProps[];
}

const ButtonsBoard = ({ buttons }: ButtonsBoardProps) => {
  const [ref, { width, height }] = useSize();

  const aspectRatio = width / height;
  const groupSize = Math.floor(Math.sqrt(buttons.length) * aspectRatio);
  const lines = Math.ceil(buttons.length / groupSize);
  const groups = groupSize
    ? groupBy(buttons, Math.min(groupSize, Math.ceil(buttons.length / lines)))
    : [];

  return (
    <div ref={ref} className={style.buttonsBoard}>
      {groups.map((line, index) => (
        <ButtonsLine key={index} buttons={line} />
      ))}
    </div>
  );
};

export default ButtonsBoard;
