import { currentBlock } from '../../../model/amrap';
import { AmrapWod } from '../../../model/wod';

interface AmrapScoreProps {
  amrapWod: AmrapWod;
  reps: number;
}

const AmrapScore = ({ amrapWod, reps }: AmrapScoreProps) => {
  const block = currentBlock(amrapWod, reps);

  if (!block) {
    return null;
  }

  return (
    <>
      <div>{`Score: ${block.completedRounds}R + ${block.repsDoneInCurrentRound}`}</div>
      <div>{`${block.remainingInBlock} ${block.exercise}`}</div>
    </>
  );
};

export default AmrapScore;
