export const Exercises = [
  'Pull Ups',
  'C2B',
  'Muscle Up',
  'T2B',
  'T2R',
  'K2E',
  'KB Swings',
  'Push Ups',
  'HRPUs',
  'HSPUs',
  'Wall Walks',
  'DB Snatches',
  'KB Snatches',
  'Snatches',
  'Air Squats',
  'Back Squats',
  'Front Squats',
  'DB Front Squats',
  'Cleans',
  'DB Cleans',
  'C&Js',
  'DB C&Js',
  'KB C&Js',
  'Push Presses',
  'DB Push Presses',
  'KB Push Presses',
  'Push Jerks',
  'DB Push Jerks',
  'DB Push Jerks',
  'STOHs',
  'DB STOHs',
  'KB STOHs',
  'OHS',
  'DB OHS',
  'Lunges',
  'Front Lunges',
  'Back Lunges',
  'Box Jumps',
  'OH Lunges',
  'BJOs',
  'BBJOs',
  'Burpees',
].sort();
