import { Wod } from '../../../model/wod';

import AmrapMenu from './AmrapMenu';

interface WodMenuProps {
  wod: Wod;
  setWod: (wod: Wod) => void;
}

const WodMenu = ({ wod, setWod }: WodMenuProps) => {
  switch (wod.type) {
    case 'AMRAP':
      return <AmrapMenu amrap={wod} setAmrap={setWod} />;
    default:
      return null;
  }
};

export default WodMenu;
