import { useCallback } from 'react';

import { Sound, play } from '../../../utils/sound';

import style from './Button.module.scss';

export interface ButtonProps {
  onClick: () => void;
  text: string;
}

const Button = ({ text, onClick }: ButtonProps) => {
  const handleClick = useCallback(() => {
    play(Sound.ButtonClick);
    onClick();
  }, [onClick]);

  return (
    <div onClick={handleClick} className={style.container}>
      <div className={style.pushable}>{text}</div>
    </div>
  );
};

export default Button;
