import { Wod, WodType } from '../../../model/wod';

import AmrapScore from './AmrapScore';
import UpScore from './UpScore';

interface ScoreProps {
  wod: Wod;
  reps: number;
}

const Score = ({ wod, reps }: ScoreProps) => {
  switch (wod.type) {
    case WodType.UP:
      return <UpScore upWod={wod} reps={reps} />;
    case WodType.AMRAP:
      return <AmrapScore amrapWod={wod} reps={reps} />;
  }
};

export default Score;
