import { UpWod } from '../../../model/wod';

interface UpScoreProps {
  upWod: UpWod;
  reps: number;
}

const UpScore = ({ reps }: UpScoreProps) => {
  return <div>{`Score: ${reps}`}</div>;
};

export default UpScore;
