import { useCallback, useState } from 'react';

import AppStateProvider, { useAppState } from '../context/appContext';
import { Sound, play } from '../utils/sound';

import AppMenu from './appMenu';
import Buttons from './buttons';
import Counter from './counter';

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { reps } = useAppState();

  const setMenuHandler = useCallback(
    (open: boolean) => () => {
      setMenuOpen(open);
      if (open) {
        play(Sound.DrawerOpen);
      }
    },
    [setMenuOpen],
  );

  return (
    <>
      <AppStateProvider>
        <AppMenu isOpen={menuOpen} onClose={setMenuHandler(false)} />
        <Counter onOpenDrawer={setMenuHandler(true)} />
        <Buttons />
      </AppStateProvider>
    </>
  );
};

export default App;
