import { ReactNode, createContext, useCallback, useContext, useState } from 'react';

import { Wod, WodType } from '../model/wod';

export interface AppStateModel {
  wod: Wod;
  setWod: (wod: Wod) => void;
  setWodType: (wodType: WodType) => void;
  reps: number;
  setReps: (reps: number) => void;
  incrementReps: (inc: number) => void;
}

export const AppContext = createContext<AppStateModel>({
  wod: {
    type: WodType.UP,
  },
  setWod: () => {
    // do nothing
  },
  setWodType: () => {
    // do nothing
  },
  reps: 0,
  setReps: () => {
    // do nothing
  },
  incrementReps: () => {
    // do nothing
  },
});

interface AppContextProviderProps {
  children: ReactNode;
}

export const useAppState = () => useContext(AppContext);

const AppContextProvider = ({ children }: AppContextProviderProps) => {
  const [wod, setWod] = useState<Wod>({ type: WodType.UP });
  const [reps, setReps] = useState<number>(0);

  const setWodType = useCallback(
    (wodType: WodType) => {
      setReps(0);
      switch (wodType) {
        case WodType.UP:
          setWod({ type: WodType.UP });
          break;
        case WodType.AMRAP:
          setWod({
            type: WodType.AMRAP,
            exercises: [],
          });
          break;
      }
    },
    [setWod, setReps],
  );

  const incrementReps = useCallback(
    (inc: number) => {
      setReps(reps + inc);
    },
    [reps],
  );

  const value = {
    wod,
    setWod,
    setWodType,
    reps,
    setReps,
    incrementReps,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
