import { useAppState } from '../../context/appContext';
import { currentBlock } from '../../model/amrap';
import { Wod, WodType } from '../../model/wod';

import ButtonsBoard from './buttonsBoard';

const buttonsForWod = (wod: Wod, reps: number, incrementReps: (value: number) => void) => {
  const defaultButtons = [1, 3, 5, 10].map((value) => ({
    text: `+${value}`,
    onClick: () => incrementReps(value),
  }));

  switch (wod.type) {
    case WodType.AMRAP: {
      const block = currentBlock(wod, reps);

      return block
        ? [
            {
              text: 'Terminar Ronda',
              onClick: () => incrementReps(block.remainingInRound),
            },
            ...(wod.exercises.length > 1
              ? [
                  {
                    text: 'Terminar Bloque',
                    onClick: () => incrementReps(block.remainingInBlock),
                  },
                ]
              : []),
            ...defaultButtons,
          ]
        : defaultButtons;
    }
    default:
      return defaultButtons;
  }
};

const Buttons = () => {
  const { reps, incrementReps, wod } = useAppState();

  /*
  const buttons = [
    {
      text: 'Terminar Bloque',
      onClick: () => incrementReps(block.remainingInBlock),
    },
    {
      text: 'Terminar Ronda',
      onClick: () => incrementReps(block.remainingInRound),
    },
    ...fixedButtons,
  ];
  */

  return <ButtonsBoard buttons={buttonsForWod(wod, reps, incrementReps)} />;
};

export default Buttons;
