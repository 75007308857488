export enum WodType {
  UP = 'UP',
  AMRAP = 'AMRAP',
}

type ExerciseInWod = {
  exercise: string;
  reps: number;
};

export type UpWod = {
  type: WodType.UP;
};

export type AmrapWod = {
  type: WodType.AMRAP;
  exercises: ExerciseInWod[];
};

export type Wod = UpWod | AmrapWod;
